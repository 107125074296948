@import '../../vars.css';
:root {
    --input-text-size: 0.75rem;
}
.container-left {
    float: left;
    width: 385px;
    margin-right: 20px;
}
.container-left>p {
    margin-bottom: 25px;
    text-align: center;
}
.container-left>p .button+.button {
    margin-left: 24px;
}
.container-left .button-dropdown {
    float: right;
}
.container-left .button-group {
    float: left;
    clear: left;
    margin-bottom: 28px;
}
.container-left .container-tags {
    float: left;
    margin-top: 4px;
}
.container-left .tag {
    margin-left: 20px;
}
.container-left .heading {
    clear: both;
}
.container-left .alert {
    margin: 20px 0px 0px;
    text-align: left;
}
.container-center {
    float: left;
    width: 220px;
}
.container-center p:first-child, .container-center .valid, .container-center .invalid {
    position: relative;
    width: 130px;
}
.container-center textarea, .container-center input {
    width: 100%;
}
.container-center p+p {
    margin-top: 15px;
}
.container-center .text-input-hint {
    min-width: 64px;
}
.container-center textarea {
    height: 48px;
}
.container-right {
    float: right;
    width: 255px;
}
.container-right .container-switches {
    float: right;
    margin: 3px 0px 0px 10px;
}
.container-right .switch {
    float: left;
    clear: both;
    margin: 4px 0px 23px;
}
.container-right .switch-square {
    clear: none;
    margin: -1px 0px 0px 15px;
}
.container-right .container-options {
    margin: 3px 0px 19px;
    text-align: center;
}
.container-right .option {
    margin: 1px 6px;
}
.container-right .select {
    width: 100%;
    margin: 2px 0px 22px;
}
.container-right .progress {
    margin-bottom: 20px;
}
.container-right .hr {
    margin: 22px 0px 0px;
}
.container .table {
    clear: right;
    float: right;
    width: 495px;
    margin-top: 15px;
}
.container .table th:first-child, .container .table td:first-child {
    width: 7%;
    text-align: center;
}
.container .table th:nth-child(3), .container .table td:nth-child(3) {
    width: 25%;
}
.container .table th:last-child, .container .table td:last-child {
    width: 15%;
}
.check-out {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    font-size: 1.3rem;
    color: #fff200;
    text-align: center;
}
a {
    color: #4ddbfc;
}
a {
    color: rgb(238, 238, 238);
    text-decoration: underline;
}
a:hover {
    color: white;
}
#new-task-form {
    display: flex;
}
.form-container {
    padding: 4px 0;
    margin: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.form-container * {
    max-width: 100% !important;
    min-width: 0% !important;
}
.form-row {
    align-items: center;
    justify-content: center;
    width: 100%;
    display: inline-flex;
    flex-direction: row;
}
.form-group {
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    padding: 10px;
}
.form-input {
    width: 100%;
    height: 2rem;
}
.form-group {
    width: 100%;
    border: none;
    outline: none;
    background: inherit;
    box-shadow: inset 2px 2px 2px #171717, inset -2px -2px 2px rgb(68, 60, 60);
}
.input-field {
    margin: 0;
    padding: 2px 4px;
    width: 100%;
    flex-grow: 1;
}
.input-field .input-field-label input {
    margin: 0;
    padding: 4px 2px;
    width: 100%;
    outline: none;
}
.input-field .input-field-label {
    margin: 0;
    padding: 2px 0;
    width: 100%;
}
.input-field .input-field-select {
    margin: 0;
    padding: 4px 2px;
    width: 100%;
}
.input-field .input-field-text {
    margin: 0;
    padding: 2px 0;
    width: 100%;
}
.input-field.input-field-inline .input-field-label {
    display: inline;
    padding: 4px 4px;
    margin: 0;
    width: 100%;
    width: auto;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;
}
.input-field.input-field-inline .input-field-label .input-field-label-text {
    min-width: 1rem !important;
    overflow-wrap: normal!important;
    white-space: nowrap;
    width: auto;
}
.input-field.input-field-inline .input-field-label .input-field-checkbox {
    width: auto;
}
.button-form-submit {
    width: 100%;
    align-self: center;
    text-align: center;
}
.form-submit-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
}
input[type="text"], input[type="email"], input[type="password"], input[type="url"], input[type="search"], textarea {
    display: inline-block;
    vertical-align: top;
    padding: 0px 7px;
    height: 1.5rem;
    color: rgb(185, 185, 185);
    text-shadow: rgba(0, 0, 0, 0.498039) 0px 1px;
    border-width: 1px;
    border-style: solid;
    border-color: black rgb(17, 17, 17) rgb(17, 17, 17);
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 1px inset, rgba(255, 255, 255, 0.0784314) 0px 1px;
    transition: 0.1s ease-out;
    background: #333333;
    background-color: #333333;
    max-width: 100%;
}
input[type="text"]:focus, input[type="password"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="search"]:focus, textarea:focus {
    color: rgb(223, 223, 223);
    outline: none;
    background: #3d3d3d;
    background-color: #3d3d3d;
}
textarea {
    padding: 4px 7px;
    height: 67px;
    line-height: 19px;
    overflow: auto;
    resize: none;
}
*::-webkit-input-placeholder {
    color: rgb(128, 128, 128);
}
.text-input-hint {
    display: block;
    position: absolute;
    top: 0px;
    left: 100%;
    margin: 0px 0px 0px 8px;
    padding: 4px 8px 5px;
    min-width: 140px;
    line-height: 18px;
    font-size: 11px;
    text-shadow: rgba(0, 0, 0, 0.8) 0px 1px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(17, 17, 17) rgb(17, 17, 17) black;
    border-radius: 3px;
    box-shadow: rgba(255, 255, 255, 0.0980392) 0px 1px inset, rgba(0, 0, 0, 0.14902) 0px 1px 1px;
    background: linear-gradient( rgba(255, 255, 255, 0.0666667), rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0) 51%, rgba(0, 0, 0, 0.2)) padding-box rgb(51, 55, 60);
}
.text-input-hint::before, .text-input-hint::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0px;
    margin: -4px 0px 0px -4px;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-color: transparent rgb(51, 55, 60) transparent transparent;
    border-width: 4px 4px 4px 0px;
}
.text-input-hint::before {
    margin-left: -5px;
    border-right-color: black;
}
.valid .text-input-hint {
    background-color: rgb(57, 99, 48);
}
.valid .text-input-hint::after {
    border-right-color: rgb(57, 99, 48);
}
.invalid .text-input-hint {
    background-color: rgb(141, 46, 43);
}
.invalid .text-input-hint::after {
    border-right-color: rgb(141, 46, 43);
}
.search {
    position: relative;
}
.search>input {
    width: 100%;
    height: 27px;
    padding: 0px 12px 0px 28px;
    border-radius: 14px;
}
.search::before {
    content: "";
    position: absolute;
    top: 7px;
    left: 9px;
    width: 6px;
    height: 6px;
    border: 2px solid rgb(153, 153, 153);
    border-radius: 7px;
}
.search::after {
    content: "";
    position: absolute;
    top: 17px;
    left: 16px;
    width: 6px;
    height: 2px;
    border-radius: 1px;
    transform: rotate(45deg);
    background: rgb(153, 153, 153);
}
input[type="search"] {
    -webkit-appearance: textfield;
    appearance: textfield;
}
*::-webkit-search-decoration, *::-webkit-search-cancel-button {
    -webkit-appearance: none;
    appearance: none;
}
.lt-ie9 .search::before, .lt-ie9 .search::after {
    content: none;
}
.lt-ie9 .search>input {
    padding: 0px 6px;
}
.checkbox, .radio {
    display: block;
    height: 100%;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(17, 17, 17) rgb(17, 17, 17) black;
    border-radius: 2px;
    box-sizing: border-box;
    box-shadow: rgba(255, 255, 255, 0.0784314) 0px 1px inset, rgba(255, 255, 255, 0.0784314) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.247059) 0px -1px 2px inset, rgba(0, 0, 0, 0.0980392) 0px 1px 2px;
    background: linear-gradient( rgba(255, 255, 255, 0.0784314), rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0) 51%, rgba(0, 0, 0, 0.247059)) padding-box rgb(70, 74, 79);
}
.checkbox::before, .radio::before {
    content: "";
    display: none;
}
input:checked+.checkbox::before, input:checked+.radio::before {
    display: block;
}
input:checked+.blue.checkbox, input:checked+.blue.radio {
    background-image: linear-gradient(rgb(40, 123, 189), rgb(22, 75, 127));
    background-color: rgb(34, 101, 161);
}
input:focus+.checkbox, input:focus+.radio, input.focus+.checkbox, input.focus+.radio {
    background-color: rgb(88, 93, 103);
}
.checkbox::before {
    position: absolute;
    top: 5px;
    left: 4px;
    width: 8px;
    height: 3px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.8);
    border-width: 0px 0px 2px 2px;
    transform: rotate(-45deg);
    box-shadow: rgba(0, 0, 0, 0.2) -1px 0px, rgba(0, 0, 0, 0.498039) 0px 1px;
}
.radio {
    border-radius: 9px;
}
.radio::before {
    margin: 5px;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    box-shadow: rgba(255, 255, 255, 0.0980392) 0px 1px;
    background: rgb(17, 17, 17);
}
.lt-ie9 .option>input {
    position: static;
    padding: 0px;
}
.lt-ie9 .checkbox, .lt-ie9 .radio {
    display: none;
}
.dropdown {
    position: relative;
    display: block;
    margin-top: 0.5em;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
}
select {
    /* Make sure the select is wider than the container so we can clip the arrow */
    /* Remove select styling */
    appearance: none;
    -webkit-appearance: none;
    /* Ugly Firefox way of doing it */
    -moz-appearance: window;
    text-indent: 0.01px;
    text-overflow: "";
    /* Magic font size number to prevent iOS text zoom */
    font-size: var(--input-text-size);
    font-weight: bold;
    border: none;
    color: #444;
    outline: none;
    /* Padding works surpringly well */
    padding: 0.125rem 0.8rem;
    margin: 0.2em;
    width: 100%;
    font-family: helvetica, sans-serif;
    border: 1px solid #bbb;
    border-radius: 0.3em;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
    background: #f3f3f3;
    /* Old browsers */
    background: -moz-linear-gradient( top, #ffffff 0%, #e5e5e5 100%);
    /* FF3.6+ */
    background: -webkit-gradient( linear, left top, left bottom, color-stop(0%, #ffffff), color-stop(100%, #e5e5e5));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient( top, #ffffff 0%, #e5e5e5 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient( top, #ffffff 0%, #e5e5e5 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #ffffff 0%, #e5e5e5 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
    /* W3C */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e5e5e5', GradientType=0);
    /* IE6-9 */
}

/* This hides native dropdown button arrow in IE */


/* SELECT INPUT STYLING */

.select {
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: 200px;
    height: 31px;
    height: 1.5rem;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(17, 17, 17) rgb(17, 17, 17) black;
    border-radius: 3px;
    box-sizing: border-box;
    box-shadow: rgba(255, 255, 255, 0.0784314) 0px 1px inset, rgba(255, 255, 255, 0.0784314) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.247059) 0px -1px 2px inset, rgba(0, 0, 0, 0.0980392) 0px 1px 2px;
    background: linear-gradient( rgba(255, 255, 255, 0.0784314), rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0) 51%, rgba(0, 0, 0, 0.247059)) rgb(70, 74, 79);
}
.select:hover {
    background-color: rgb(77, 81, 89);
}
select::-ms-expand {
    display: none;
}
.select>select {
    display: block;
    position: relative;
    width: 112%;
    height: 29px;
    line-height: 17px;
    margin: 0px;
    padding: 6px 6px 6px 10px;
    color: rgb(221, 221, 221);
    text-shadow: rgba(0, 0, 0, 0.701961) 0px -1px;
    border: 0px;
    appearance: none;
    -webkit-appearance: none;
    background: rgba(0, 0, 0, 0);
}
.select>select:focus {
    z-index: 2;
    width: 100%;
    outline: rgb(35, 110, 171) solid 1px;
    outline-offset: -1px;
}
.select::before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 13px;
    right: 12px;
    width: 0px;
    height: 0px;
    border-width: 5px;
    border-style: solid;
    border-color: rgb(221, 221, 221) transparent transparent;
    pointer-events: none;
    box-shadow: rgba(0, 0, 0, 0.498039) 0px -1px;
}
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    border: none;
    padding: 0.25rem 0.3rem;
    margin: 2px 0;
    width: 100%;
    font-family: inherit;
    cursor: inherit;
    line-height: inherit;
    z-index: 1;
    font-size: var(--font-size-table);
    outline: none;
}
select::-ms-expand {
    display: none;
}

/* Custom select dropdown arrow */

select {
    width: 100%;
    min-width: 15ch;
    max-width: 30ch;
    border: 1px solid var(--select-border);
    border-radius: 0.25em;
    padding: 0.25em 0.5em;
    font-size: 1.25rem;
    cursor: pointer;
    line-height: 1.1;
    background-color: #fff;
    background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
    align-items: center;
}
select::after {
    content: "";
    width: 0.8em;
    height: 0.5em;
    background-color: var(--select-arrow);
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    justify-self: end;
}
select {
    display: grid;
    grid-template-areas: "select";
    align-items: center;
    position: relative;
    min-width: 15ch;
    max-width: 30ch;
    border: 1px solid var(--select-border);
    border-radius: 0.25em;
    padding: 0.25em 0.5em;
    font-size: var(--font-size-table);
    cursor: pointer;
    line-height: 1.1;
    background-color: #fff;
    background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
}
.select select, .select::after {
    grid-area: select;
}
.select:not(.select--multiple)::after {
    content: "";
    justify-self: end;
    width: 0.8em;
    height: 0.5em;
    background-color: var(--select-arrow);
    -webkit-clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
}
select:focus+.focus {
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border: 2px solid var(--select-focus);
    border-radius: inherit;
}
select[multiple] {
    padding-right: 0;
    /*
   * Safari will not reveal an option
   * unless the select height has room to 
   * show all of it
   * Firefox and Chrome allow showing 
   * a partial option
   */
    height: 8rem;
    /* 
   * Experimental - styling of selected options
   * in the multiselect
   * Not supported crossbrowser
   */
    width: 100%;
    height: 8em;
    resize: both;
}
select[multiple] option {
    white-space: normal;
    outline-color: var(--select-focus);
}
.select--disabled {
    cursor: not-allowed;
    background-color: #eee;
    background-image: linear-gradient(to top, #ddd, #eee 33%);
}
label {
    font-weight: 500;
}
.select+label {
    margin-top: 1rem;
}
.select option {
    text-shadow: none;
}
.option {
    vertical-align: top;
    width: 100%;
    height: 18px;
}
.option>input {
    left: 0px;
    width: inherit;
    height: inherit;
    opacity: 0;
}
option {
    background-color: #ffffff;
}
option:before {
    content: ">";
    font-size: 20px;
    display: none;
    padding-right: 10px;
    padding-left: 5px;
    color: #fff;
}
option.option.option-selected {
    background-color: #9bc0ed;
}

/*

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    border: none;
    padding: 0 1em 0 0;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    z-index: 1;
    outline: none;
}
select::-ms-expand {
    display: none;
}
.select {
    display: grid;
    grid-template-areas: "select";
    align-items: center;
    position: relative;
    min-width: 15ch;
    max-width: 30ch;
    border: 1px solid var(--select-border);
    border-radius: 0.25em;
    padding: 0.25em 0.5em;
    font-size: 1.25rem;
    cursor: pointer;
    line-height: 1.1;
    background-color: #fff;
    background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
}
.select select, .select::after {
    grid-area: select;
}
.select:not(.select--multiple)::after {
    content: "";
    justify-self: end;
    width: 0.8em;
    height: 0.5em;
    background-color: var(--select-arrow);
    -webkit-clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
}
select:focus+.focus {
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border: 2px solid var(--select-focus);
    border-radius: inherit;
}
select[multiple] {
    padding-right: 0;

height: 6rem;

}
select[multiple] option {
    white-space: normal;
    outline-color: var(--select-focus);
}
.select--disabled {
    cursor: not-allowed;
    background-color: #eee;
    background-image: linear-gradient(to top, #ddd, #eee 33%);
}
label {
    font-size: 1.125rem;
    font-weight: 500;
}
.select+label {
    margin-top: 2rem;
}
*/


/*
*
*
*
*
*/

.switch {
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: 32px;
    user-select: none;
    -webkit-user-select: none;
}
.switch>label {
    display: block;
    position: relative;
    height: 13px;
    border-width: 1px;
    border-style: solid;
    border-color: black rgb(17, 17, 17) rgb(17, 17, 17);
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 1px inset, rgba(255, 255, 255, 0.0784314) 0px 1px;
    transition: 0.15s ease-out;
    background: rgb(51, 51, 51);
}
.switch>label::before {
    content: "";
    display: block;
    margin: -3px 0px 0px -3px;
    width: 15px;
    height: 15px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(17, 17, 17) rgb(17, 17, 17) black;
    border-radius: 9px;
    box-shadow: rgba(255, 255, 255, 0.0784314) 0px 1px inset, rgba(255, 255, 255, 0.0784314) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.247059) 0px -1px 2px inset, rgba(0, 0, 0, 0.0980392) 0px 1px 2px;
    background: linear-gradient(rgb(80, 84, 91), rgb(53, 56, 61)) rgb(70, 74, 79);
}
.switch>input {
    position: absolute;
    z-index: 0;
    top: 2px;
    left: 2px;
    width: 28px;
    height: 9px;
    padding: 0px;
    opacity: 0;
    appearance: none;
    -webkit-appearance: none;
}
.switch>input:focus {
    opacity: 1;
    outline: rgb(35, 110, 171) solid 1px;
    outline-offset: 4px;
}
.switch>input:checked+label {
    padding-left: 19px;
    background: linear-gradient(rgb(30, 95, 157), rgb(41, 114, 169)) rgb(34, 101, 161);
}
.switch-square {
    width: 58px;
}
.switch-square>label {
    height: 23px;
    line-height: 20px;
    font-size: 10px;
    color: rgb(136, 136, 136);
    text-align: center;
    text-transform: uppercase;
    text-shadow: rgba(0, 0, 0, 0.4) 0px 1px;
    border-radius: 2px;
}
.switch-square>label::before {
    margin: -1px 0px 0px -1px;
    width: 26px;
    height: 21px;
    border-radius: 2px;
}
.switch-square>label::after {
    content: attr(data-off);
    position: absolute;
    top: 0px;
    right: 0px;
    width: 29px;
}
.switch-square>input {
    width: 54px;
    height: 19px;
}
.switch-square>input:checked+label {
    padding-left: 30px;
}
.switch-square>input:checked+label::after {
    content: attr(data-on);
    left: 0px;
    color: rgb(207, 219, 226);
}
.lt-ie9 .switch {
    width: 30px;
    height: 11px;
    border: 1px solid black;
    background: rgb(51, 51, 51);
}
.lt-ie9 .switch>label {
    display: none;
}
.lt-ie9 .switch>input {
    position: static;
    width: inherit;
    height: inherit;
    vertical-align: top;
}
.lt-ie9 .switch-square {
    width: 56px;
    height: 21px;
}
.valid {
    position: relative;
}
.valid>input {
    color: rgb(183, 190, 183);
    background: rgb(34, 51, 31);
}
.invalid {
    position: relative;
}
.invalid>input {
    color: rgb(198, 181, 188);
    background: rgb(81, 39, 36);
}
.button {
    font-weight: bold;
    align-self: center;
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
    vertical-align: middle;
    height: 2rem;
    padding: 0px 12px;
    font-size: 12px;
    color: rgb(221, 221, 221);
    text-align: center;
    text-shadow: rgba(0, 0, 0, 0.8) 0px 1px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(17, 17, 17) rgb(17, 17, 17) black;
    border-radius: 3px;
    cursor: pointer;
    box-sizing: border-box;
    box-shadow: rgba(255, 255, 255, 0.0980392) 0px 1px inset, rgba(0, 0, 0, 0.298039) 0px -1px 3px inset, rgba(255, 255, 255, 0.0784314) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.14902) 0px 1px 2px;
    background: linear-gradient( rgba(255, 255, 255, 0.0784314), rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0) 51%, rgba(0, 0, 0, 0.247059)) padding-box rgb(70, 74, 79);
}
.button:hover, .button:focus, .button.focus {
    color: rgb(221, 221, 221);
    outline: none;
    background-color: rgb(83, 88, 95);
}
.button:active, .button-dropdown:hover>.toggle, .button-dropdown.open>.toggle, .button.active {
    color: rgb(204, 204, 204);
    border-color: black rgb(17, 17, 17) rgb(17, 17, 17);
    box-shadow: rgba(0, 0, 0, 0.247059) 0px 1px 2px inset, rgba(255, 255, 255, 0.0784314) 0px 1px;
    background: linear-gradient( rgba(0, 0, 0, 0.0980392), transparent 50%, rgba(255, 255, 255, 0.0666667)) rgb(50, 53, 56);
}
a.button {
    line-height: 110px;
    text-decoration: none;
}
.button-round {
    padding: 0px 16px;
    border-radius: 16px;
}
.button-blue {
    background-color: rgb(34, 101, 161);
}
.button-blue:hover, .button-blue:focus, .button-blue.focus {
    background-color: rgb(38, 114, 182);
}
.button-blue:active, .button-blue.active {
    background-color: rgb(27, 80, 127);
}
.button-green {
    background-color: rgb(63, 118, 54);
}
.button-green:hover, .button-green:focus, .button-green.focus {
    background-color: rgb(72, 135, 62);
}
.button-green:active, .button-green.active {
    background-color: rgb(48, 90, 41);
}
.button-red {
    background-color: rgb(141, 45, 43);
}
.button-red:hover, .button-red:focus, .button-red.focus {
    background-color: rgb(161, 51, 49);
}
.button-red:active, .button-red.active {
    background-color: rgb(110, 35, 33);
}
.button-purple {
    background-color: rgb(113, 66, 142);
}
.button-purple:hover, .button-purple:focus, .button-purple.focus {
    background-color: rgb(127, 74, 159);
}
.button-purple:active, .button-purple.active {
    background-color: rgb(91, 53, 114);
}
.button-group {
    display: inline-block;
    vertical-align: top;
    height: 32px;
    font-size: 0px;
    white-space: nowrap;
}
.button-group>.button {
    margin-left: -1px;
    height: inherit;
    line-height: 30px;
    padding: 0px 16px;
    border-radius: 0px;
}
.button-group>.button:first-child {
    margin-left: 0px;
    border-radius: 3px 0px 0px 3px;
}
.button-group>.button:last-child {
    border-radius: 0px 3px 3px 0px;
}
.button-group>.button:focus {
    position: relative;
    z-index: 1;
}
.button-group {
    display: flex;
    align-items: center;
    justify-content: stretch;
    vertical-align: middle;
    height: auto;
    white-space: nowrap;
}
.button-group .button {
    height: auto;
}
.button-group.button-column {
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: stretch;
    vertical-align: middle;
    white-space: nowrap;
}
.button-group.button-column .button {
    padding: auto;
    margin: 0 !important;
    height: 1rem;
}
.button-group.button-column>.button:first-child {
    margin-left: 0px;
    border-radius: 3px 3px 0px 0px;
}
.button-group.button-column>.button:last-child {
    border-radius: 0px 0px 3px 3px;
}
.button-dropdown {
    display: inline-block;
    vertical-align: top;
    position: relative;
    height: 32px;
    font-size: 0px;
    white-space: nowrap;
}
.button-dropdown>.button {
    position: relative;
    z-index: 1;
    height: inherit;
    line-height: 30px;
    padding: 0px 12px;
    border-radius: 3px 0px 0px 3px;
}
.button-dropdown>.toggle {
    width: 31px;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-stretch: normal;
    font-size: 0px;
    line-height: 0;
    font-family: serif;
    color: transparent;
    border-left-width: 0px;
    border-radius: 0px 3px 3px 0px;
    user-select: none;
    -webkit-user-select: none;
}
.button-dropdown>.toggle::before, .button-dropdown>.toggle::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -1px 0px 0px -5px;
    width: 0px;
    height: 0px;
    border-width: 5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.8) transparent transparent;
}
.button-dropdown>.toggle::after {
    margin-top: -2px;
    border-top-color: rgb(200, 200, 200);
}
.button-dropdown:hover>.dropdown, .button-dropdown.open>.dropdown {
    display: block;
}
.button-dropdown:hover>.button, .button-dropdown.open>.button {
    border-bottom-left-radius: 0px;
}
.button-dropdown:hover>.toggle, .button-dropdown.open>.toggle {
    border-bottom-right-radius: 0px;
}
.button-prev, .button-next {
    position: relative;
    height: 32px;
    width: 32px;
    padding: 0px;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-stretch: normal;
    font-size: 0px;
    line-height: 0;
    font-family: serif;
    color: transparent;
}
.button-prev::before, .button-next::before, .button-prev::after, .button-next::after {
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -5px;
    width: 0px;
    height: 0px;
    border: 5px outset transparent;
}
.button-prev::before, .button-next::before {
    margin-top: -4px;
}
.button-prev::before, .button-prev::after {
    left: 50%;
    margin-left: -10px;
    border-right-width: 8px;
    border-right-style: solid;
    border-right-color: rgba(0, 0, 0, 0.8);
}
.button-prev::after {
    border-right-color: rgb(200, 200, 200);
}
.button-next::before, .button-next::after {
    right: 50%;
    margin-right: -10px;
    border-left-width: 8px;
    border-left-style: solid;
    border-left-color: rgba(0, 0, 0, 0.8);
}
.button-next::after {
    border-left-color: rgb(200, 200, 200);
}
.dropdown {
    display: none;
    position: absolute;
    top: 100%;
    left: 0px;
    right: 0px;
    margin-top: -1px;
    font-size: 12px;
    border: 1px solid black;
    border-radius: 0px 0px 3px 3px;
    box-shadow: rgba(0, 0, 0, 0.14902) 0px 1px 2px;
    background: rgb(51, 51, 51);
}
.dropdown:hover {
    display: block;
}
.dropdown>li>a {
    display: block;
    line-height: 30px;
    padding: 0px 12px;
    color: rgb(198, 198, 198);
    text-decoration: none;
    text-shadow: rgba(0, 0, 0, 0.8) 0px 1px;
    box-shadow: rgba(255, 255, 255, 0.0470588) 0px 0px 0px 1px inset;
}
.dropdown>li>a:hover, .dropdown>li>a:focus {
    outline: none;
    background: rgb(61, 61, 61);
}
.dropdown>li+li {
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: rgb(17, 17, 17);
}
.dropdown>li:last-child>a {
    border-radius: 0px 0px 2px 2px;
}
.lt-ie9 .button-prev::before, .lt-ie9 .button-next::before, .lt-ie9 .button-prev::after, .lt-ie9 .button-next::after {
    border-style: solid;
}
.heading {
    overflow: hidden;
    line-height: 30px;
    padding: 0px 11px;
    text-shadow: rgba(0, 0, 0, 0.8) 0px 1px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(17, 17, 17) rgb(17, 17, 17) black;
    border-radius: 3px;
    box-shadow: rgba(255, 255, 255, 0.0980392) 0px 1px inset, rgba(255, 255, 255, 0.0470588) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.14902) 0px 1px 2px;
    background: linear-gradient(rgb(41, 122, 184), rgb(21, 82, 143)) padding-box rgb(29, 97, 159);
}
.heading>h2 {
    float: left;
    font-size: 13px;
    font-weight: bold;
    color: rgb(236, 236, 236);
}
.heading-links {
    float: right;
    margin: 0px -11px 0px 11px;
}
.heading-links>li {
    float: left;
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: rgba(0, 0, 0, 0.701961);
    box-shadow: rgba(255, 255, 255, 0.0666667) 1px 0px inset, rgba(255, 255, 255, 0.0666667) -1px 0px;
}
.heading-link {
    display: block;
    color: rgb(223, 223, 223);
    padding: 0px 12px;
    text-decoration: none;
}
.heading-link:hover, .heading-link:focus {
    color: white;
    background: rgba(255, 255, 255, 0.0784314);
}
.heading-link:active {
    color: rgba(223, 223, 223, 0.8);
    text-shadow: rgba(0, 0, 0, 0.498039) 0px 1px;
    box-shadow: rgba(0, 0, 0, 0.298039) 0px 1px 2px inset;
    background: rgb(16, 76, 136);
}
.tag {
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin-bottom: 1px;
    padding: 0px 8px 1px 22px;
    line-height: 21px;
    font-size: 11px;
    color: rgb(221, 221, 221);
    text-decoration: none;
    text-shadow: rgba(0, 0, 0, 0.8) 0px 1px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(17, 17, 17) rgb(17, 17, 17) black;
    border-radius: 3px;
    box-shadow: rgba(255, 255, 255, 0.0980392) 0px 1px inset, rgba(0, 0, 0, 0.298039) 0px -1px 3px inset, rgba(255, 255, 255, 0.0784314) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.14902) 0px 1px 2px;
    background: linear-gradient( rgba(255, 255, 255, 0.0784314), rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0) 51%, rgba(0, 0, 0, 0.247059)) padding-box rgb(70, 74, 79);
}
.tag:hover, .tag:focus {
    color: rgb(221, 221, 221);
    outline: none;
    background-color: rgb(79, 84, 90);
}
.tag:active {
    margin-top: 1px;
    margin-bottom: 0px;
}
.tag::before {
    content: "";
    position: absolute;
    top: 8px;
    left: 8px;
    width: 4px;
    height: 4px;
    border: 1px solid black;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px inset, rgba(255, 255, 255, 0.2) 0px 1px;
    background: rgb(60, 59, 64);
}
.tag-blue {
    background-color: rgb(18, 91, 118);
}
.tag-blue:hover, .tag-blue:focus {
    background-color: rgb(21, 108, 140);
}
.tag-teal {
    background-color: rgb(19, 113, 94);
}
.tag-teal:hover, .tag-teal:focus {
    background-color: rgb(23, 135, 112);
}
.alert {
    position: relative;
    line-height: 30px;
    padding: 0px 11px;
    text-shadow: rgba(0, 0, 0, 0.8) 0px 1px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(17, 17, 17) rgb(17, 17, 17) black;
    border-radius: 3px;
    box-shadow: rgba(255, 255, 255, 0.0980392) 0px 1px inset, rgba(0, 0, 0, 0.14902) 0px 1px 2px;
    background: linear-gradient(rgb(151, 58, 56), rgb(115, 35, 34)) padding-box rgb(130, 45, 43);
}
.alert.notice {
    background: linear-gradient(rgb(69, 112, 63), rgb(44, 79, 37)) rgb(54, 92, 47);
}
.alert>strong {
    font-weight: bold;
    color: rgb(236, 236, 236);
}
.alert-close {
    display: block;
    position: absolute;
    top: 50%;
    right: 0px;
    margin-top: -9px;
    padding: inherit;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-stretch: normal;
    font-size: 18px;
    line-height: 18px;
    font-family: Arial, sans-serif;
    color: rgb(210, 210, 210);
    text-decoration: none;
}
.alert-close:hover {
    color: rgb(236, 236, 236);
}

/* Table Styling */


/*
.table {
    width: 100%;
    line-height: 32px;
    text-align: left;
    text-shadow: rgba(0, 0, 0, 0.8) 0px 1px;
    border-collapse: separate;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.14902) 0px 1px 2px;
    background: rgb(51, 51, 51);
}
.table-head>tr>th {
    padding: 0px 11px;
    font-weight: bold;
    border-width: 1px 1px 1px 0px;
    border-style: solid;
    border-color: rgb(17, 17, 17);
    box-shadow: rgba(255, 255, 255, 0.0980392) 0px 1px inset, rgba(255, 255, 255, 0.0196078) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.0980392) 0px 1px 1px;
    background: linear-gradient(rgb(73, 78, 85), rgb(56, 59, 66)) padding-box rgb(61, 65, 71);
}
.table-head>tr>th:first-child {
    border-left-width: 1px;
    border-top-left-radius: 3px;
}
.table-head>tr>th:last-child {
    border-top-right-radius: 3px;
}
.table-body>tr:nth-child(2n) {
    background: rgb(46, 46, 46);
}
.table-body>tr:hover {
    background: rgb(61, 61, 61);
}
.table-body>tr:last-child>td {
    border-bottom-color: black;
}
.table-body>tr:last-child>td:first-child {
    border-bottom-left-radius: 3px;
}
.table-body>tr:last-child>td:last-child {
    border-bottom-right-radius: 3px;
}
.table-body>tr>td {
    padding: 0px 11px;
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: rgb(17, 17, 17);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgb(17, 17, 17);
    box-shadow: rgba(255, 255, 255, 0.0392157) 0px 0px 0px 1px inset;
}
.table-body>tr>td:first-child {
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: rgb(17, 17, 17);
}
*/

.progress {
    position: relative;
    height: 12px;
    border-width: 1px;
    border-style: solid;
    border-color: black rgb(17, 17, 17) rgb(17, 17, 17);
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 1px inset, rgba(255, 255, 255, 0.0784314) 0px 1px;
    background: rgb(51, 51, 51);
}
.progress>span {
    display: block;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    min-width: 12px;
    border-radius: 7px;
    box-shadow: rgba(255, 255, 255, 0.14902) 0px 1px inset, rgba(0, 0, 0, 0.498039) 1px 0px 1px;
    background: linear-gradient(rgb(121, 133, 151), rgb(85, 95, 107)) rgb(101, 112, 126);
}
.progress-square, .progress-square>span {
    border-radius: 2px;
}
.progress-blue>span {
    background: linear-gradient(rgb(41, 122, 184), rgb(21, 82, 143)) rgb(32, 101, 163);
}
.header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2;
    height: 3em;
    overflow: hidden;
    padding: .5em;
    color: #fff;
    line-height: 1.2;
    background-color: #0e5bb8;
    border-bottom: solid 1px #ddd;
    box-shadow: 1px 1px 10px #333;
}
.iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    width: 100%;
    min-height: 100%;
    padding-top: 4em;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
label {
    display: block;
    /* margin-top: 2em; */
    font-size: calc(var(--input-text-size) * 1.125);
    color: #777;
}

/* Custom arrow - could be an image, SVG, icon font, etc. */

.dropdown:after {
    background: none;
    color: #bbb;
    content: "\25BC";
    font-size: var(--input-text-size);
    padding: 0;
    position: absolute;
    right: 1em;
    top: 1.2em;
    bottom: 0.3em;
    z-index: 1;
    /* This hack makes the select behind the arrow clickable in some browsers */
    pointer-events: none;
}

/* Hover style - tricky because we're clipping the overflow */

.dropdown:hover {
    border: 1px solid #888;
}

/* Focus style */

select:focus {
    box-shadow: 0 0 1px 1px rgba(180, 222, 250, 0.85);
}

/* This hides focus around selected option in FF */

select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}

/* These are just demo button-y styles, style as you like */


/*

.button {
    border: 1px solid #bbb;
    border-radius: 0.3em;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
    background: #f3f3f3;
    background: -moz-linear-gradient( top, #ffffff 0%, #e5e5e5 100%);
    background: -webkit-gradient( linear, left top, left bottom, color-stop(0%, #ffffff), color-stop(100%, #e5e5e5));
    background: -webkit-linear-gradient( top, #ffffff 0%, #e5e5e5 100%);
    background: -o-linear-gradient( top, #ffffff 0%, #e5e5e5 100%);
    background: -ms-linear-gradient(top, #ffffff 0%, #e5e5e5 100%);
    background: linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e5e5e5', GradientType=0);
}
*/

textarea {
    white-space: pre;
    overflow-wrap: break-word;
    width: 100%;
    height: auto;
    max-height: 100px;
}

/*
.progress {
    height: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    background-color: #f5f5f5;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}
.progress-bar {
    float: left;
    width: 0%;
    height: 100%;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    background-color: #337ab7;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    -webkit-transition: width 0.6s ease;
    -o-transition: width 0.6s ease;
    transition: width 0.6s ease;
}
.progress-striped .progress-bar, .progress-bar-striped {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    -webkit-background-size: 40px 40px;
    background-size: 40px 40px;
}
.progress.active .progress-bar, .progress-bar.active {
    -webkit-animation: progress-bar-stripes 2s linear infinite;
    -o-animation: progress-bar-stripes 2s linear infinite;
    animation: progress-bar-stripes 2s linear infinite;
}
.progress-bar-success {
    background-color: #5cb85c;
}
.progress-striped .progress-bar-success {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-info {
    background-color: #5bc0de;
}
.progress-striped .progress-bar-info {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-warning {
    background-color: #f0ad4e;
}
.progress-striped .progress-bar-warning {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-danger {
    background-color: #d9534f;
}
.progress-striped .progress-bar-danger {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
*/


/*
    .progress-bar-container {
        -webkit-animation: progress-bar-stripes 2s linear infinite;
        -o-animation: progress-bar-stripes 2s linear infinite;
        animation: progress-bar-stripes 2s linear infinite;
        width: 100%;
        padding: 5px;
        margin: 50px;
        -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    }
    .progress-bar-filler {
        height: 100%;
        padding: 5px;
        border-radius: inherit;
    }
    .progress-bar-label {
        padding: 5px;
    }
*/

input[type='range'] {
    -webkit-appearance: none;
    height: 7px;
    background: grey;
    border-radius: 5px;
    background-image: linear-gradient(#d46a6a, #d46a6a);
    background-repeat: no-repeat;
}
input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #d46a6a;
    cursor: pointer;
    box-shadow: 0 0 2px 0 #555;
}
input[type='range']::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
}
input[type="range"]::-webkit-slider-thumb:hover {
    box-shadow: #d46a6a50 0px 0px 0px 8px;
}
input[type="range"]::-webkit-slider-thumb:active {
    box-shadow: #d46a6a50 0px 0px 0px 11px;
    transition: box-shadow 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, left 350ms cubic- bezier(0.4, 0, 0.2, 1) 0ms, bottom 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
input[type='range']::-webkit-slider-thumb {
    transition: background 0.3s ease-in-out;
}
@import '../../vars.css';

/* Spinner Styling */

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.loader-spinner-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    align-content: center;
    width: 100%;
}
.loader-text-message {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.loader-text-progress {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.loader-progress-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    align-content: center;
    flex-direction: column;
    width: 100%;
}
.loader-progress-message {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}
.loader-progress-message-container {
    padding-right: 2rem;
    padding-left: 2rem;
}
.loader-progress-message-container, .loader-progress-bar-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    align-content: center;
    width: 100%;
}
.loader-time-elapsed, .loader-success-count, .loader-failure-count {
    display: flex;
    flex-direction: row;
    padding-right: 2rem;
    padding-left: 2rem;
    max-width: 100%;
    word-wrap: normal !important;
    white-space: nowrap;
    text-transform: capitalize;
    min-width: min-content;
    width: min-content;
}
.loader-success-count {
    color: var(--color_primary);
}
.loader-failure-count {
    color: var(--color-error);
}
svg.spinner {
    -webkit-animation: rotate 2s linear infinite;
    animation: rotate 2s linear infinite;
    z-index: 2;
    /*position: absolute;*/
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
    width: 50px;
    height: 50px;
}
svg.spinner .path {
    stroke: #111111;
    stroke-linecap: round;
    -webkit-animation: dash 1.5s ease-in-out infinite;
    animation: dash 1.5s ease-in-out infinite;
}
svg.spinner.hidden {
    display: none;
}
@-webkit-keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}
@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}
@-webkit-keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}
@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}

/* Progress Bar Styling (from Bootstrap CDN */

@-webkit-keyframes progress-bar-stripes {
    from {
        background-position: 40px 0;
    }
    to {
        background-position: 0 0;
    }
}
@-o-keyframes progress-bar-stripes {
    from {
        background-position: 40px 0;
    }
    to {
        background-position: 0 0;
    }
}
@keyframes progress-bar-stripes {
    from {
        background-position: 40px 0;
    }
    to {
        background-position: 0 0;
    }
}
@import '../../../vars.css';

/* SIDE PANEL (right) STYLING */

.side-panel-container {
    height: 100%;
    width: var(--sidepanel-width);
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1000;
    background-color: var(--color_tertiary);
    color: white;
    position: absolute;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    box-shadow: 0px 0px 10px var(--border-color);
}
.side-panel-container.hidden {
    width: 0;
    display: none;
}
.side-panel {
    flex-grow: 1;
    width: 100% !important;
    max-height: calc( 100% - var(--header-height));
    /* flex: 0 1 auto; */
    position: relative;
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    height: auto;
    height: 100%;
    max-height: 100%;
    min-height: 100%;
    max-width: 100%;
}
.side-panel-header {
    border-bottom: 1px solid grey;
    height: var(--header-height);
    display: flex;
    flex-direction: row;
}
.side-panel-header-buttons {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex: 1 1 auto;
}
.side-panel-close-button {
    padding: 2px;
    background-color: transparent;
}
.side-panel-header-buttons2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.side-panel-close-button2 {
    position: absolute;
    top: 0;
    left: 0;
    padding: 2px;
    background-color: transparent;
}
.side-panel-label {
    overflow-wrap: break-word;
    margin: 0;
    padding: 0.25rem 0.25rem;
    font-size: 0.75rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1 1 100%;
    white-space: wrap;
    overflow-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
}
.side-panel-content-title {
    width: 100% !important;
    max-width: 100% !important;
    overflow-wrap: break-word;
    padding: 0.5rem 1rem;
    font-size: 0.75rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.side-panel-body {
    flex-grow: 1;
    height: 100%;
    width: 100% !important;
    max-width: 100% !important;
    overflow-wrap: break-word;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    box-shadow: inset 0px 0px 10px var(--border-color);
    overflow-y: auto;
    max-width: 100%;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    background: var(--color_header);
}
.side-panel.body .side-panel-content {
    min-width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    padding: 10px !important;
    flex-grow: 1;
    overflow-y: auto;
}
.side-panel-footer {
    border-bottom: 1px solid grey;
    height: var(--footer-height);
    height: 20px;
    background-color: var(--color_header);
    display: flex;
    align-items: center;
    padding: 0 15px;
    height: var( --header-height);
    border-right: 1px groove var( --border-color);
    border-bottom: 1px solid var( --border-color);
}
@import './vars.css';

/* absikyut */


/* default styles here for older browsers. 
   I tend to go for a 600px - 960px width max but using percentages
*/

@media only screen and (min-width: 960px) {
    /* styles for browsers larger than 960px; */
}
@media only screen and (min-width: 1440px) {
    /* styles for browsers larger than 1440px; */
}
@media only screen and (min-width: 2000px) {
    /* for sumo sized (mac) screens */
}
@media only screen and (max-device-width: 480px) {
    /* styles for mobile browsers smaller than 480px; (iPhone) */
}
@media only screen and (device-width: 768px) {
    /* default iPad screens */
}

/* different techniques for iPad screening */

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
    /* For portrait layouts only */
}
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
    /* For landscape layouts only */
}
* {
    /*font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
Playfair Display SC"*/
    font-family: 'Montserrat', sans-serif;
    font-family: "Source Sans Pro", sans-serif;
}
*, ::after, ::before {
    box-sizing: border-box;
    font-size: var(--body-font-size);
    line-height: var(--bs-body-line-height);
    overflow-wrap: break-word !important;
}
html, body {
    min-height: 100%;
    margin: 0 !important;
    padding: 0 !important;
}
body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    background-color: var(--color_secondary);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0 !important;
    padding: 0 !important;
    background-color: #fff;
    font-family: helvetica, sans-serif;
    max-height: 100%;
    height: 100vh;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.flex-panel {
    display: flex;
    position: relative;
    justify-content: flex-start;
    align-content: flex-start;
    flex-direction: column;
    height: 100%;
    width: 100%;
}
.flex-panel .flex-panel-element {
    padding: 1rem;
    display: block;
    position: relative;
    justify-content: space-between;
    align-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    width: 100%;
}
.flex-row {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    align-content: center;
    width: 100%;
}
.flex-col {
    display: flex;
    align-items: center;
    flex-direction: column;
    align-content: center;
    flex-direction: row;
    width: 100%;
}
.flex-spread-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 0;
    margin-right: 0;
    justify-content: space-between !important;
    width: 100%;
}
h1, h2, p {
    color: var(--color_textColor);
    padding: 0;
    margin: 0;
}
p.paragraphwide {
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    font-size: 0.65rem;
}
button, a.button {
    font-size: var(--font-size-button);
    font-weight: 300;
    color: var(--color-button-text);
    text-align: center;
}
.options-container, .pagination-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    align-content: center;
    width: 100%;
}
.flex-set {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.input-group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
}

/* Smaller screens */

@media only screen and (max-width: 767px) {
    .flex-set {
        width: 100%;
        height: min-content;
        flex-wrap: nowrap;
        flex-direction: column;
        flex: 1 1 auto;
    }
    .input-group {
        width: 100%;
        height: min-content;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
    }
}

/* Bigger screens */

@media only screen and (min-width: 768px) {
    .flex-set {
        width: 100%;
        height: auto;
        flex-direction: row;
        flex: 1 1 auto;
    }
    .input-group {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
    }
}

/* MAIN CONTENT STYLING */

.page-content, .page-sidebar, .page-container, .map-sidebar, .page-wrapper .sidebar-wrapper, .sidebar-wrapper .sidebar-brand>a, .sidebar-wrapper .sidebar-dropdown>a:after, .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before, .sidebar-wrapper ul li a i, .page-wrapper .page-content, .sidebar-wrapper .sidebar-search input.search-menu, .sidebar-wrapper .sidebar-search .input-group-text, .sidebar-wrapper .sidebar-menu ul li a, #show-sidebar, #close-sidebar {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.container {
    display: grid;
    grid-template-rows: 1fr auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
}
.page-container {
    position: absolute;
    min-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    margin: 0 !important;
    padding: 0 !important;
    background-color: var(--color_body);
    min-height: 100%;
}
.page-container2 {
    min-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 0 !important;
    padding: 0 !important;
    background-color: var(--color_primary);
}
.page-content2 {
    position: absolute;
    width: calc( 100% - var(--sidebar-width));
    max-width: calc( 100% - var(--sidebar-width));
    min-height: calc( 100% - var(--header-height));
    margin: 0 !important;
    left: calc(var(--sidebar-width));
    top: calc(var( --header-height));
}
.page-content3 {
    position: absolute;
    width: calc( 100% - var(--sidebar-width));
    max-width: calc( 100% - var(--sidebar-width));
    min-height: calc( 100% - var(--header-height));
    margin: 0 !important;
    left: calc(var(--sidebar-width));
    top: calc(var( --header-height));
    display: flex;
    flex-direction: row;
    align-items: stretch;
    border: 1px solid black;
}
.page-content4 {
    position: absolute;
    width: calc( 100% - var(--sidebar-width));
    max-width: calc( 100% - var(--sidebar-width));
    min-height: calc( 100% - var(--header-height));
    margin: 0 !important;
    top: calc(var( --header-height));
    display: flex;
    flex-direction: row;
    align-items: stretch;
    border: 1px solid black;
}
.page-sidebar, .page-content {
    /* flex: 0 1 auto; */
    position: relative;
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    height: auto;
    height: 100%;
    max-height: 100vh;
    min-height: 100%;
    max-width: 100%;
}
.page-content {
    width: calc(100% - var(--sidebar-width));
    flex-grow: 1;
    /* left: var(--sidebar-width); */
}
.page-sidebar {
    width: var(--sidebar-width);
    background-color: var(--color_secondary);
    z-index: 200;
    flex: 0 1 auto;
}
.page-sidebar.hidden {
    width: 0;
}
.page-sidebar.hidden * {
    display: none;
    width: 0 !important;
}
.dashboard-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*margin: 4px !important;*/
    /*width: 100%;*/
    width: auto;
    margin: 4px;
    background-color: var(--color_body);
    overflow: hidden;
    height: calc( 100% - var(--header-height));
    max-height: calc( 100% - var(--header-height));
}

/* Header Styling */

.header-section {
    color: var(--color_highlightColor);
    font-size: 1.5rem;
    line-height: 2rem;
}
.header-subsection {
    color: var(--color_highlightColor2);
    font-size: 1.25rem;
    line-height: 1.75rem;
}
.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}
.page-header {
    position: sticky;
    background-color: var(--color_header);
    z-index: 1000;
    margin: 0 !important;
    padding: 0;
    height: var(--header-height);
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center !important;
    justify-content: space-between;
}
.page-header-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
}
.page-header-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
}
.page-header-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
}
.page-header-title-container {
    display: flex;
    justify-content: left !important;
    max-height: 100%;
}
.page-header-title {
    padding: 0 0.5rem;
    display: flex;
    vertical-align: middle;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-shadow: rgba(0, 0, 0, 0.8) 0px 1px;
    margin: 0;
    font-size: 1.125rem;
    line-height: 1.25rem;
    font-weight: 600;
}
.page-header-icon-container {
    padding: 0 10px;
    border-right: 1px solid grey;
}
.menu-toggle {
    display: flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    padding: 0px 10px;
}
.menu-toggle-button {
    cursor: pointer;
    color: white;
}
.menu-toggle-button:hover {
    color: var(--color-button-bg-hover);
}
img.avatar-icon {
    border-radius: 50%;
    width: 50%;
}
.avatar-icon {
    cursor: pointer;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    max-width: calc(var(--header-height) - 20px);
    max-height: calc(var(--header-height) - 20px);
    position: relative;
}
.page-header-icon {
    cursor: pointer;
    max-height: calc(var(--header-height) - 20px);
    position: relative;
}
.page-header-nav-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.section-title {
    padding: 1rem;
    padding-left: 0 !important;
    margin: 0;
    gap: 2.5rem;
    display: flex;
    flex-direction: row;
    align-items: center !important;
    justify-content: flex-start !important;
    max-width: 100%;
}
.section-title-text {
    margin: auto 0;
}
.section-title-bar {
    margin: 4px;
    width: 25vw;
    height: 1px !important;
    background-color: white;
}
@media (min-width: 300px) {
    .section-title {
        flex-direction: column;
        margin: 0;
        gap: 1.5rem;
    }
    .section-title-bar {
        width: 90%;
    }
}
@media (min-width: 600px) {
    .section-title {
        flex-direction: column;
    }
    .section-title-bar {
        width: 90%;
    }
}
@media (min-width: 900px) {
    .section-title {
        flex-direction: row;
    }
    .section-title-bar {
        width: 25vw;
    }
}
@media (min-width: 1200px) {
    .section-title {
        flex-direction: row;
    }
    .section-title-bar {
        width: 25vw;
    }
}
.nav-button-group {
    vertical-align: top;
    font-size: 0px;
    white-space: nowrap;
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.nav-button-group .header-nav-button {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    display: block;
    text-align: center;
    background-color: transparent;
    border-color: rgba( 0, 0, 0, 0.2);
    border-width: 0px;
}
.nav-button-group .header-nav-button.active {
    background-color: var(--color-button-bg-active);
}
.nav-button-group .header-nav-button:hover {
    background-color: var(--color-button-bg-hover);
    opacity: 0.5;
    border-color: rgba( 0, 0, 0, 0.5);
    box-shadow: inset 0px 0px 10px var(--border-color);
    text-shadow: rgba(0, 0, 0, 0.8) 0px 1px;
}
.nav-button-group .header-nav-button:hover, .nav-button-group .header-nav-button:focus .nav-button-group .header-nav-button:active {
    background-color: var(--color-button-bg-hover);
    box-shadow: inset 0px 0px 10px var(--border-color);
    text-shadow: rgba(0, 0, 0, 0.8) 0px 1px;
}
.nav-button-group .header-nav-button:focus {
    opacity: 0.75;
    border-color: rgba( 0, 0, 0, 0.5);
}
.nav-button-group .header-nav-button:active {
    opacity: 0.95;
    border-color: rgba( 0, 0, 0, 0.5);
}
.button-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.header-nav-button {
    width: auto;
    height: 100%;
}

/* SIDEBAR STYLING */

.sidebar {
    left: 0;
    width: var(--sidebar-width);
    height: 100%;
    background-color: var(--color_secondary);
    position: fixed;
    z-index: 200;
    max-height: calc( 100% - var( --header-height));
    overflow-y: auto;
}
.sidebar2 {
    top: calc(var( --header-height));
    left: 0;
    width: var(--sidebar-width);
    height: 100%;
    background-color: var(--color_secondary);
    position: fixed;
    z-index: 200;
    max-height: calc( 100% - var( --header-height));
    overflow-y: auto;
}
.sidebar-header {
    background-color: var(--color_header);
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    border-right: 1px groove var( --border-color);
    border-bottom: 1px solid var( --border-color);
    position: sticky !important;
    z-index: 1000;
    margin: 0 !important;
    height: var(--header-height) !important;
    min-height: var(--header-height) !important;
    width: 100%;
}
.sidebar-header .sidebar-title {
    font-size: 18px;
}

/* 
Sidebar hierarchy: 
    .sidebar-header
        .sidebar-icon
        .sidebar-title
        .sidebar-subtitle
    .sidebar-body
        .sidebar-nav
            .sidebar-nav-label
            .sidebar-nav-item
                .sidebar-nav-link
                .sidebar-nav-text
*/

.sidebar-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    box-shadow: inset 0px 0px 10px var(--border-color);
    overflow-y: auto;
    max-width: 100%;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    background: var(--color_header);
}
.sidebar-body .query-form {
    min-width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    padding: 10px !important;
    flex-grow: 1;
    overflow-y: auto;
}
.sidebar-body .sidebar-nav {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
}
.sidebar-body .sidebar-nav .sidebar-nav-label {
    padding: 10px 10px;
    display: flex;
    text-align: left;
    width: 100%;
    padding-left: 9px;
    margin-bottom: 8px;
    margin: 0px 10px;
    border-bottom: 1px groove var( --border-color);
    color: #fff;
}
.sidebar-body .sidebar-nav .sidebar-nav-item {
    width: 100%;
    cursor: pointer;
    border-bottom: 0.1rem dotted var( --border-color);
}
.sidebar-body .sidebar-nav .sidebar-nav-item .sidebar-nav-link {
    width: 100%;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: left;
    transition: background-color 0.3s ease;
    color: #fff;
}
.sidebar-body .sidebar-nav .sidebar-nav-link:hover {
    background-color: var(--color-table-hover);
    /* box-shadow: 0px 3px var( --border-color), -1em 0 .4em var( --color-table-hover); */
    box-shadow: 6px 6px 12px #434343, -6px -6px 12px #5b5b5b;
}
.sidebar-body .sidebar-nav .sidebar-nav-link:active {
    background-color: var(--color-table-hover);
    /* box-shadow: 0px 3px var( --border-color), -1em 0 .4em var( --color-table-hover); */
    box-shadow: inset 6px 6px 12px #434343, inset -6px -6px 12px #5b5b5b;
}
.sidebar-body .sidebar-nav .sidebar-nav-link.active {
    background-color: var(--color-button-bg);
}
.sidebar-body .sidebar-nav .sidebar-nav-link .sidebar-nav-text {
    color: #fff;
}
.sidebar-footer {
    height: 20px;
    background-color: var(--color_header);
    display: flex;
    align-items: center;
    padding: 0 15px;
    height: var( --header-height);
    border-right: 1px groove var( --border-color);
    border-bottom: 1px solid var( --border-color);
}

/* width */

 ::-webkit-scrollbar {
    width: 8px;
}

/* Track */


/*
        ::-webkit-scrollbar-track {
            background: #060b12;
            box-shadow: inset 0 0 5px grey;
            background-color: #242b44;
        }
    */


/* Handle */

 ::-webkit-scrollbar-thumb {
    background: #2d3e49;
    border-radius: 10px;
}

/* Handle on hover */

 ::-webkit-scrollbar-thumb:hover {
    background: #3c526f;
}

/* LIST STYLING */

ul.obj-list {
    max-width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
    list-style: none;
}
.obj-list-item {
    max-width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
}
ul.obj-list li.obj-list-item :not(:has(div)) {
    display: inline;
}
.obj-list-item .obj-list-key, .obj-list-item .obj-list-value {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
    text-transform: capitalize;
    padding-top: 0;
    padding-bottom: 0;
    margin: 4px 0;
}
.obj-list-item .obj-list-key {
    background-color: rgba( 0, 0, 0, 0.2);
}
.obj-list-item .obj-list-value {
    background-color: transparent;
}
.obj-list-value ul.obj-list {
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
}
ul.obj-list {
    counter-reset: item;
}
ul.obj-list li.obj-list-item {
    counter-increment: item;
}
li.obj-list-item ul.obj-list {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    position: relative;
    padding-inline-start: 0.125rem;
    padding-inline-end: 0.125rem;
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
}
ul.obj-list li:before {
    display: inline-block;
}
ul.obj-list, ol {
    padding-right: 0.25rem;
    padding-left: 1.5rem;
}
ul.obj-list ul.obj-list, ul.obj-list ol, ol ul.obj-list, ol ol {
    padding-left: 1rem;
}
ul.obj-list, ol {
    margin: 0.25rem 0;
}

/*

ol {
    list-style: none;
    counter-reset: item;
}
ol li {
    counter-increment: item;
}
ol li:before {
    margin-right: 10px;
    content: counters(item, ".") " ";
    display: inline-block;
}
ol {
    list-style: none;
    position: relative;
    padding-left: 3em;
    margin: 0;
}
ol {
    counter-reset: level0 0;
}
ol li::before {
    content: counter(level0, decimal) " ";
    counter-increment: level0;
    position: absolute;
    right: 100%;
    margin-right: 0.625em;
    text-align: right;
    font-weight: bold;
    font-size: 0.8em;
}
ol ol {
    counter-reset: level1;
}
ol ol li::before {
    content: counter(level0, decimal) "." counter(level1, decimal) " ";
    counter-increment: level1;
    position: absolute;
    right: 100%;
    margin-right: 0.625em;
    text-align: right;
    font-weight: bold;
    font-size: 0.8em;
}
ol ol ol {
    counter-reset: level2;
}
ol ol ol li::before {
    content: counter(level0, decimal) "." counter(level1, decimal) "." counter(level2, decimal) " ";
    counter-increment: level2;
    position: absolute;
    right: 100%;
    margin-right: 0.625em;
    text-align: right;
    font-weight: bold;
    font-size: 0.8em;
}
dt {
    font-weight: bold;
}
dd {
    margin-left: 2em;
}

*/


/* file tree lists */

.file-tree ul {
    font-family: courier;
    margin: 0;
    padding-left: 3rem;
    list-style: none;
    line-height: 1.25;
    position: relative;
    overflow: hidden;
}
.file-tree li:not(:last-child)>ul::before {
    content: "\2502\0020\2502\0020\2502\0020\2502\0020\2502\0020\2502\0020\2502\0020\2502\0020\2502\0020\2502\0020\2502\0020\2502\0020\2502\0020\2502\0020\2502\0020\2502\0020\2502\0020\2502\0020\2502\0020\2502\0020";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 1em;
}
.file-tree>ul {
    margin: 0;
    padding: 0;
}
.file-tree li {
    background: #fff;
    position: relative;
}
.file-tree li::before {
    content: "\251C\2500\2500\0020";
}
.file-tree li:last-child::before {
    content: "\2514\2500\2500\0020";
}

/* BUTTON STYLING */

.button-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}
.close-button {
    cursor: pointer;
    background-color: transparent;
    color: white;
    border: none;
    font-size: 1rem;
}
button.close-button {
    color: black;
    float: left;
    padding: 3px;
    margin: 0px 0px;
    border-radius: 5px;
    box-shadow: var(--box-shadow-params-table-cell);
    border-width: 0;
    font-size: var(--font-size-button);
    background-color: var(--color_highlightColor);
}
.close-button:hover {
    background-color: var(--color_highlightColor2) !important;
    color: var(--color-button-bg-hover);
}
button.close-button:active {
    color: red !important;
    background-color: var( --color-table-body) !important;
    box-shadow: inset 0px 0px 2px var(--color_box_shadow_hover);
}
.button {
    font-weight: bold;
    align-self: center;
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
    vertical-align: middle;
    height: 2rem;
    padding: 0px 12px;
    font-size: var(--font-size-button);
    color: var(--color-button-text);
    text-align: center;
    text-shadow: rgba(0, 0, 0, 0.8) 0px 1px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(17, 17, 17) rgb(17, 17, 17) black;
    border-radius: 3px;
    cursor: pointer;
    box-sizing: border-box;
    box-shadow: rgba(255, 255, 255, 0.0980392) 0px 1px inset, rgba(0, 0, 0, 0.298039) 0px -1px 3px inset, rgba(255, 255, 255, 0.0784314) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.14902) 0px 1px 2px;
    background: var(--color-button-bg);
}
.button:hover, .button:focus, .button.focus {
    color: rgb(143, 170, 200);
    outline: none;
    background: var(--color-button-bg);
}
.button:active, .button-dropdown:hover>.toggle, .button-dropdown.open>.toggle, .button.active {
    color: rgb(204, 204, 204);
    border-color: black rgb(17, 17, 17) rgb(17, 17, 17);
    box-shadow: rgba(0, 0, 0, 0.247059) 0px 1px 2px inset, rgba(255, 255, 255, 0.0784314) 0px 1px;
    background: var(--color-button-bg-hover);
}
a.button {
    line-height: 110px;
    text-decoration: none;
}
.button-round {
    padding: 0px 16px;
    border-radius: 16px;
}
.button-blue {
    background-color: rgb(34, 101, 161);
}
.button-blue:hover, .button-blue:focus, .button-blue.focus {
    background-color: rgb(38, 114, 182);
}
.button-blue:active, .button-blue.active {
    background-color: rgb(27, 80, 127);
}
.button-green {
    background-color: rgb(63, 118, 54);
}
.button-green:hover, .button-green:focus, .button-green.focus {
    background-color: rgb(72, 135, 62);
}
.button-green:active, .button-green.active {
    background-color: rgb(48, 90, 41);
}
.button-red {
    background-color: rgb(141, 45, 43);
}
.button-red:hover, .button-red:focus, .button-red.focus {
    background-color: rgb(161, 51, 49);
}
.button-red:active, .button-red.active {
    background-color: rgb(110, 35, 33);
}
.button-purple {
    background-color: rgb(113, 66, 142);
}
.button-purple:hover, .button-purple:focus, .button-purple.focus {
    background-color: rgb(127, 74, 159);
}
.button-purple:active, .button-purple.active {
    background-color: rgb(91, 53, 114);
}
.button-group {
    display: inline-block;
    vertical-align: top;
    height: 32px;
    font-size: 0px;
    white-space: nowrap;
}
.button-group>.button {
    margin-left: -1px;
    height: inherit;
    line-height: 30px;
    padding: 0px 16px;
    border-radius: 0px;
}
.button-group>.button:first-child {
    margin-left: 0px;
    border-radius: 3px 0px 0px 3px;
}
.button-group>.button:last-child {
    border-radius: 0px 3px 3px 0px;
}
.button-group>.button:focus {
    position: relative;
    z-index: 1;
}
.button-group {
    display: flex;
    align-items: center;
    justify-content: stretch;
    vertical-align: middle;
    height: auto;
    max-width: 100%;
    white-space: nowrap;
}
.button-group .button {
    height: auto;
}
.button-group.button-column {
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    white-space: nowrap;
}
.button-group.button-column .button {
    padding: auto;
    margin: 0 !important;
    height: 1rem;
}
.button-group.button-column>.button:first-child {
    margin-left: 0px;
    border-radius: 3px 3px 0px 0px;
}
.button-group.button-column>.button:last-child {
    border-radius: 0px 0px 3px 3px;
}
.option-buttons-container {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
}
.option-button {
    padding: 2px 6px !important;
    background-color: var(--color-button-bg);
    font-size: 8px;
}
.option-button.option-button-active {
    background-color: var(--color_highlightColor);
}

/* Tab Component Styling */

.tab-list-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.tab-list {
    border-bottom: 1px solid #ccc;
    padding-left: 0;
}
.tab-list .tab-item {
    display: inline-block;
    list-style: none;
    margin-bottom: -1px;
    padding: 0.5rem 0.75rem;
}
.tab-list .tab-item.tab-active {
    background-color: white;
    border: solid #ccc;
    border-width: 1px 1px 0 1px;
}

.button-list-container {
    width: 100%;
    height: auto;
    flex: 0 1 1;
    box-shadow: inset 0px 0px 12px 1px #000000;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;
    padding: 2px;
}
.button-list-label {
    color: white;
}
/* // Neumorphic list-buttons // 
.buttons-container {
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 395px;
    height: 100px;
    border-radius: 45px;
    background: #151515;
    box-shadow: inset 0 0 2px 2px rgba(0, 0, 0, 0.5);
}
ul {
    list-style: none;
    width: inherit;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
}
ul li {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1px;
    background: #202020;
    border-top: 1px solid #353535;
    box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.5);
    transition: all 0.5s;
}
ul li:nth-of-type(1) {
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
}
ul li:last-of-type {
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
}
ul li:hover {
    cursor: pointer;
}
ul li a {
    display: inline-block;
    color: gray;
    text-decoration: none;
    font-size: 40px;
    transition: all 0.5s;
}
*/

hr.hr-section-border {
    width: 80% !important;
    display: block;
    unicode-bidi: isolate;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    margin-inline-start: auto;
    margin-inline-end: auto;
    overflow: hidden;
    /* box-shadow: xpos ypos spread clamp <color> */
    box-shadow: inset 0px 0px 4px 1px rgba(0, 0, 0, 0.5);
    border-style: inset;
    border-width: 1px;
}

/* Mapbox styling */

.marker {
    /*background-image: url('https://upload.wikimedia.org/wikipedia/commons/a/a0/Circle_-_black_simple.svg');*/
    background-size: cover;
    border-radius: 50%;
    cursor: pointer;
    -webkit-transition: transform(scale) 0.3s ease-in-out;
    -moz-transition: transform(scale) 0.3s ease-in-out;
    -ms-transition: transform(scale) 0.3s ease-in-out;
    -o-transition: transform(scale) 0.3s ease-in-out;
    transition: transform(scale) 0.3s ease-in-out;
}
.marker.coordinates-marker {
    background-color: #ff2222bb;
    width: 10px;
    height: 10px;
    border: 1px solid white;
    z-index: 100;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.5);
}
.marker.coordinates-marker:hover {
    content: "X";
    background-color: #ff2222bb;
    width: 12px;
    height: 12px;
}

.sidebar-button-row {
    background-color: var(--color_tertiary);
    color: white;
    width: 100%;
    padding: 0px 0px;
    display: flex;
    border: 1px solid #ffffff55;
    height: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.sidebar-button-group-container {
    display: flex;
    align-items: center;
    justify-content: stretch;
    flex-direction: column;
    vertical-align: middle;
    height: auto;
    /* max-width: 100%; */
    width: 100%;
    /* white-space: nowrap; */
    /* display: inline-block; */
    vertical-align: top;
    /* height: 32px; */
    /* font-size: 0px; */
    white-space: nowrap;
}
.sidebar-button-row {
    display: flex;
    background-color: var(--color_tertiary);
    color: white;
    width: 100%;
    padding: 0px 0px;
    border: 1px solid #ffffff55;
}

.sidebar-button-group {
    display: flex;
    /* align-items: center; */
    /* justify-content: stretch; */
    vertical-align: middle;
    height: auto;
    /* max-width: 100%; */
    width: 100%;
    white-space: nowrap;
    display: inline-block;
    vertical-align: top;
    height: 32px;
    /* font-size: 0px; */
    white-space: nowrap;
}

.sidebar-button-group .button {
    height: auto;
}

.sidebar-button-control, .sidebar-button-controls .sidebar-button-control {
    align-self: center;
    /* background-color: #ffffff1a; */
    /* border: 1px outset; */
    /* border-color: #111 #111 #000; */
    /* border-radius: 3px; */
    color: #fffc;
    cursor: pointer;
    display: inline-flex;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    text-decoration: none;
    transition: all .35s cubic-bezier(.4,0,.2,1) 0ms;
    height: auto;
    /* padding: 0px 0px !important; */
    /* padding: 0.125rem; */
    border: 0px solid #ffffff55 !important;
    width: auto !important;
    background-color: transparent !important;
}

.sidebar-button, .sidebar-button:focus, .sidebar-button.focus {
    background-color: var(--color_tertiary);
    color: white;
    width: 100%;
    padding: 2px 4px;
    padding: auto;
    border: 0px solid #ffffff55;
}

.sidebar-button:hover {
    color: rgb(221, 221, 221);
    border-color: var(--sib_lightblue);
    background-color: var(--color_quaternary);
}
.sidebar-button:active, .sidebar-button.active {
    color: rgb(204, 204, 204);
    border-color: black;
    box-shadow: rgba(0, 0, 0, 0.247059) 0px 1px 2px inset, rgba(255, 255, 255, 0.0784314) 0px 1px;
    background-color: var(--color-button-bg-hover);
}

.sidebar-button-col {
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    gap: 0;
    display: flex;
    align-content: space-around;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    background-color: var(--color_tertiary);
    color: white;
    width: 100%;
    padding: 0px 0px;
    border: 1px solid #ffffff55;
}

.sidebar-button-info {
    padding: 0;
    height: auto;
    width: 100%;
    margin: 0;
    gap: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: stretch;
    justify-content: flex-start;
    align-items: stretch;
}

.sidebar-button-info-text {
    padding: 2px 4px;
    margin: 0;
    width: 100%;
    height: auto;
    font-size: 10px;
    font-weight: 300;
}

.data-input-cell-buttons {
    border-right: 1px solid grey;
    display: flex;
    flex-direction: row;
    padding-right: 0.5rem;
}
.button-control {
    height: auto;
    padding: 0.125rem;
}
.button-control, .button-controls .button-control {
    align-self: center;
    background-color: #ffffff1a;
    border: 1px outset;
    border-color: #111 #111 #000;
    border-radius: 3px;
    color: #fffc;
    cursor: pointer;
    display: inline-flex;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    text-decoration: none;
    transition: all .35s cubic-bezier(.4,0,.2,1) 0ms;
}
.button {
    align-items: center;
    align-self: center;
    background-color: var(--color-primary);
    box-sizing: border-box;
    color: #ddd;
    cursor: pointer;
    display: inline-flex;
    gap: 0.25rem;
    height: 2rem;
    justify-content: space-around;
    padding: 0 12px;
    vertical-align: middle;
}
[type=button], [type=reset], [type=submit], button {
    -webkit-appearance: button;
    background-color: initial;
    background-image: none;
}
[role=button], button {
    cursor: pointer;
}
button, select {
    text-transform: none;
}
button, input, optgroup, select, textarea {
    color: inherit;
    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    margin: 0;
    padding: 0;
}
.button .button-icon {
    color: var(--button-text-color);
    font-size: var(--button-font-size);
    font-weight: 400;
    height: 0.75rem;
    overflow: hidden;
    text-align: center;
    text-decoration: none;
    text-shadow: 1px 1px 2px #00000080;
    -webkit-user-select: none;
    user-select: none;
    width: 0.75rem;
}
@import '../../vars.css';
@media only screen and (max-width: 480px) {
    /* styles for mobile browsers smaller than 480px; (iPhone) */
    .table-container {
        padding: 0 2.5rem;
    }
}

/* TABLE STYLING */

.table-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0rem 1rem;
    max-height: 100% !important;
    max-width: 100% !important;
    height: 100% !important;
    width: 100% !important;
    background-color: var(--color-table-body);
    border-radius: 0;
    /*
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-shrink: 1;
    flex-grow: 1;
    */
}
.table-container .table {
    overflow: auto;
    /*margin: 1rem 0.25rem;*/
    /*padding: 1rem 0.25rem;*/
    border: 0px solid gray;
    height: 100% !important;
    border-radius: 0;
    max-height: 100% !important;
    max-width: 100% !important;
    /*
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-shrink: 1;
    flex-grow: 1;
    */
}
table>* {
    font-size: var(--font-size-table);
    width: auto;
    min-width: 0;
}
.table table {
    /*overflow: auto !important;*/
    /*margin: 1rem 0.25rem;*/
    padding: 0.0rem 0.0rem;
    border-radius: 0;
    border-spacing: 0;
    -webkit-border-horizontal-spacing: 0px;
    -webkit-border-vertical-spacing: 0px;
    border-collapse: separate;
    border-collapse: collapse;
    /* Don't collapse */
    margin: 0 auto;
    max-width: var(--max-width-table);
    max-width: 100% !important;
    width: 100%;
    max-height: 100% !important;
    table-layout: auto;
    color: var(--color-table-text);
    /*background-color: rgb(205, 205, 205);*/
    background-color: var(--color-table-body);
    font-size: var(--font-size-table);
    empty-cells: show;
    overflow: hidden;
}

/* Table block styling */

.table table thead, .table table tbody, .table table tfoot {
    background-color: var(--color-table-body);
    border: 1px solid var(--color-table-border);
}

/* Table row styling */

.table tbody tr:nth-of-type(even) {
    background-color: var(--color-table-row-even);
}
.table tbody tr:nth-of-type(odd) {
    background-color: var(--color-table-row-odd);
}

/* Table cell content styling */

.table th, .table td {
    width: auto;
    max-width: 100% !important;
}
.table thead tr th {
    padding: 0.125rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    border: 0px solid var(--color-table-border);
    cursor: pointer;
    font-size: calc(var(--font-size-table) * 1.1);
    font-weight: bold;
}
.table thead tr, .table tbody tr {
    border: 0px solid var(--color-table-border);
}
.table tfoot tr {
    border: 1px solid var(--color-table-border);
    padding-left: 2rem;
    padding-right: 2rem;
}
.table tbody tr td {
    height: 100%;
    box-shadow: var(--box-shadow-params-table-cell);
    border: 1px solid var(--color-table-border);
    color: var(--color-table-text);
    position: relative;
    margin: 0;
    padding: 0.125rem 0.5rem;
    overflow-wrap: break-word !important;
    white-space: pre-wrap;
    text-overflow: initial;
    text-transform: capitalize;
    font-size: var(--font-size-table);
    max-inline-size: inherit;
    inline-size: min-content;
    max-width: var(--max-width-table-cell);
    min-width: 1rem !important;
    width: auto;
}

/*
.table tbody tr td.col-cell {
    max-inline-size: inherit;
    inline-size: min-content;
    max-width: var(--max-width-table-cell);
    min-width: 1rem !important;
    width: auto;
}

td {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
*/

.table tbody tr:hover, tbody tr:hover::after, tbody td:hover, tbody td:hover::after {
    color: var(--color_highlightColor2) !important;
}
.table tr:hover, td:hover {
    background-color: var(--color-table-hover) !important;
    /*box-shadow: var(--box-shadow-params-table-row-hover) !important;*/
}
.table-fixed-head table {
    overflow-y: auto !important;
}
.table-fixed-head table thead, .table-fixed-head table thead th, .table-fixed-head table thead tr {
    position: sticky !important;
    top: 0 !important;
    z-index: 10 !important;
}
.table-fixed-head table thead tr {
    z-index: 10 !important;
    position: sticky !important;
    border-collapse: initial !important;
}
.table-fixed-head table tbody th {
    position: sticky !important;
    left: 0 !important;
}

/* here is the trick - use box shadows as a pseudo border */

.table .table-fixed-head table tbody:nth-of-type(1) tr:nth-of-type(1) td {
    border-top: none !important;
}
.table .table-fixed-head table thead th {
    border-top: none !important;
    border-bottom: none !important;
    box-shadow: inset 0px 1px 0px var(--color-table-border), inset 0px -1px 0px var(--color-table-border), inset 1px 0px 0px var(--color-table-border), inset -1px 0px 0px var(--color-table-border);
}

/* and one small fix for weird FF behavior, described in https://stackoverflow.com/questions/7517127/ */

.table .table-fixed-head table thead th {
    background-clip: padding-box
}

/*
th:hover::after {
    background-color: #b6b6b680 !important;
    box-shadow: var(--box-shadow-params-table-row-hover) !important;
    content: "\00a0";
    height: 10000px;
    left: 0;
    position: absolute;
    top: -5000px;
    width: 100%;
    z-index: 2;

td {
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
}
td {
    overflow: hidden;
    max-width: 10rem;
    word-wrap: break-word;
}
td {
    white-space: pre-wrap;
    text-overflow: initial;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

}
*/ .sub-table-container>table tbody tr td, .sub-table-container>table tbody tr {
    border-width: 1px !important;
}
table tbody tr td.sub-table-container {
    content: '';
    /*box-shadow: none;*/
    max-width: 100%;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    z-index: 0;
    height: 100%;
    min-height: 100%;
}
table.sub-table {
    border: 1px solid var(--color-table-border) !important;
}
table tbody tr td.sub-table-container table.sub-table {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    table-layout: auto;
}
table.sub-table tbody.sub-table-body {
    height: 100%;
    width: auto;
    margin: 0;
    padding: 0;
}
table.sub-table tbody tr.sub-table-row {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}
table.sub-table tbody tr td.sub-table-cell-key {
    inline-size: min-content;
    min-inline-size: min-content;
    width: 100%;
}
table.sub-table tbody tr td.sub-table-cell-value {
    min-inline-size: min-content;
    text-transform: capitalize;
}
.col-hidden {
    width: 0;
    display: none;
}
.table-pagination-container {
    width: 100%;
    margin: 0;
    padding: 0.125rem 1rem;
    list-style: none;
}
.table-pagination-info {
    font-size: 0.75rem;
}
.table-pagination {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
}
.table-pagination li {
    padding: 0;
    margin: 0;
}
.table-pagination li button.pagination-button {
    color: black;
    float: left;
    padding: 8px;
    margin: 0 4px;
    border-radius: 5px;
    box-shadow: var(--box-shadow-params-table-cell);
    border-width: 0;
    font-size: var(--font-size-button);
    background-color: var(--color_highlightColor);
}
.table-pagination li button.pagination-button:hover {
    background-color: var(--color_highlightColor2) !important;
}
.table-pagination li button.pagination-button:active, .table-pagination li button.pagination-button.current-page-button {
    color: red !important;
    background-color: var( --color-table-body) !important;
    background-color: var(--color_highlightColor2) !important;
    box-shadow: inset 0px 0px 2px var(--color_box_shadow_hover);
}
input[type=text].table-search-filter {
    float: none;
    padding: 4px 8px;
    font-size: var(--body-font-size);
    width: 100%;
    height: 100%;
}
input[type=text].table-search-filter:focus {
    border: 1px solid var( --color-table-body);
}
.table-pagination li span {
    font-size: 100%;
    font-weight: normal;
    font-family: "Libre Franklin", Sans-serif;
    padding: 10px 0px;
}
.table-options-container, .table-pagination-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    align-content: center;
    width: 100%;
}
.table-options {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    width: 100%;
}
.table-download-container {
    width: 100%;
    min-width: min-content;
}

/*
.table-container * {
    max-height: 100% !important;
}
.table {
    width: 100%;
    line-height: 32px;
    text-align: left;
    text-shadow: rgba(0, 0, 0, 0.8) 0px 1px;
    border-collapse: separate;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.14902) 0px 1px 2px;
    background: rgb(51, 51, 51);
}
.table-head>tr>th {
    padding: 0px 11px;
    font-weight: bold;
    border-width: 1px 1px 1px 0px;
    border-style: solid;
    border-color: rgb(17, 17, 17);
    box-shadow: rgba(255, 255, 255, 0.0980392) 0px 1px inset, rgba(255, 255, 255, 0.0196078) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.0980392) 0px 1px 1px;
    background: linear-gradient(rgb(73, 78, 85), rgb(56, 59, 66)) padding-box rgb(61, 65, 71);
}
.table-head>tr>th:first-child {
    border-left-width: 1px;
    border-top-left-radius: 3px;
}
.table-head>tr>th:last-child {
    border-top-right-radius: 3px;
}
.table-body>tr:nth-child(2n) {
    background: rgb(46, 46, 46);
}
.table-body>tr:hover {
    background: rgb(61, 61, 61);
}
.table-body>tr:last-child>td {
    border-bottom-color: black;
}
.table-body>tr:last-child>td:first-child {
    border-bottom-left-radius: 3px;
}
.table-body>tr:last-child>td:last-child {
    border-bottom-right-radius: 3px;
}
.table-body>tr>td {
    padding: 0px 11px;
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: rgb(17, 17, 17);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgb(17, 17, 17);
    box-shadow: rgba(255, 255, 255, 0.0392157) 0px 0px 0px 1px inset;
}
.table-body>tr>td:first-child {
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: rgb(17, 17, 17);
}
*/
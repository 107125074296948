@import '../../vars.css';

/* MAPBOX STYLING */

.map-content-container {
    display: flex;
    flex-direction: row;
    border: 1px solid grey;
    max-height: 100% !important;
    max-width: 100% !important;
    height: 100% !important;
    width: 100% !important;
    flex-grow: 1;
    background-color: #b8b8b8;
    border-radius: 0;
}
.map-sidebar {
    position: relative;
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    min-height: 100%;
    max-width: 100%;
    width: var(--sidebar-width);
    background-color: var(--color_secondary);
    z-index: 200;
    flex: 0 1 auto;
}
.map-sidebar.hidden {
    width: 0;
    opacity: 0;
}
.map-sidebar-content {
    padding: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.map-sidebar-controls-container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
}
.map-sidebar-button, .map-sidebar-button:focus, .map-sidebar-button.focus {
    background-color: var(--color_tertiary);
    color: white;
    width: 100%;
    padding: 2px 4px;
    border: 1px solid #ffffff55;
}
.map-sidebar-button:hover {
    color: rgb(221, 221, 221);
    border-color: var(--sib_lightblue);
    background-color: var(--color_quaternary);
}
.map-sidebar-button:active, .map-sidebar-button.active {
    color: rgb(204, 204, 204);
    border-color: black;
    box-shadow: rgba(0, 0, 0, 0.247059) 0px 1px 2px inset, rgba(255, 255, 255, 0.0784314) 0px 1px;
    background-color: var(--color-button-bg-hover);
}
.map-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
    width: 100%;
    background-color: var(--color_primary);
    overflow: hidden;
    height: 100%;
    max-height: 100%;
}
.map-content-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    height: 2rem;
    background-color: var(--color_tertiary);
}
.map-content-box {
    width: 100%;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: var(--color_textColor);
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&family=Roboto:wght@400;700&display=swap');
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700");
 :root {
    --color_primary: #343434;
    --color_secondary: #282828;
    --color_tertiary: #3e3e3e;
    --color_quaternary: #453979;
    --color_quinary: #242b44;
    --color-error: #ff2222;
    --color_header: #453979;
    --color_body: #242b44;
    --color_highlightColor: #fdad51;
    --color_highlightColor2: #fef48b;
    --color_textColor: #f2f4ec;
    --color_box_shadow_primary: #2d2d2d44;
    --color_box_shadow_secondary: #2d2d2d44;
    --color_box_shadow_hover: #2d2d2daa;
    --color-button-text: #dcdcdc;
    --color-button-bg: #5d5d5d;
    --color-button-bg-hover: #f59c34;
    --color-button-bg-active: #df8576;
    /* box-shadow: [horizontal offset] [vertical offset] [blur radius] [optional spread radius] [color]; 
box-shadow: none|h-offset v-offset blur spread color |inset|initial|inherit;
*/
    --box-shadow-params-table-cell: 2px 2px 2px 1px var(--color_box_shadow_primary);
    --box-shadow-params-table: 0px 0px 10px var(--color_box_shadow_primary);
    --box-shadow-params-table-row-hover: inset 0px 0px 10px var(--color_box_shadow_hover);
    --box-shadow-params-table-hover: 0px 0px 10px var(--color_box_shadow_hover);
    --box-shadow-params-table-bg: 9px 9px 16px var(--color_box_shadow_primary), -9px -9px 16px var(--color_box_shadow_secondary);
    --media-size-sm: 300px;
    --media-size-md: 600px;
    --media-size-lg: 900px;
    --media-size-xl: 1200px;
    --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient( 180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --bs-body-font-family: var(--bs-font-sans-serif);
    --body-font-size: 0.85rem;
    --font-size-button: 0.80rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.25;
    --font-size-table: 0.65rem;
    --color-table-body: #cdcdcd;
    --color-table-text: #444444;
    --color-table-hover: #9d9d9d;
    --color-table-row-header: #f3f3f388;
    --color-table-row-even: #88888888;
    --color-table-row-odd: #bbbbbb88;
    --color-table-border: #bbbbbb;
    --max-width-table-cell: 20rem;
    --max-width-table: 100rem;
    --secondary-color: #676767;
    --secondary-bg-color: #4a4a4a;
    --secondary-hover-color: #524524;
    --border-color: rgba( 0, 0, 0, 0.2);
    --sidebar-width: 16rem;
    --sidepanel-width: 16rem;
    --header-height: 4rem;
    --footer-height: 0px;
    --select-border: #777;
    --select-focus: blue;
    --select-arrow: var(--select-border);
}
html[data-theme='light'], .page-container.theme-light {
    --color_primary: #cecece;
    --color_secondary: #d0d0d0;
    --color_tertiary: #999999;
    --color_quaternary: #d1d1d1;
    --color_quinary: #787878;
    --color-error: #ff2222;
    --color_header: #cacaca;
    --color_body: #b9b9b9;
    --color_highlightColor: #fdad51;
    --color_highlightColor2: #fef48b;
    --color-table-hover: #9d9d9d;
    --color-button-text: #444444;
    --color-button-bg: #dcdcdc;
    --color-button-bg-hover: #f59c34;
    --color-button-bg-active: #76b3df;
    --color-table-border: #bbbbbb;
    --color-table-body: #cdcdcd;
    --color-table-text: #444444;
    --color-table-hover: #9d9d9d;
    --color-table-row-header: #f3f3f388;
    --color-table-row-even: #88888888;
    --color-table-row-odd: #bbbbbb88;
}
html[data-theme='default'], html[data-theme='grey'], .page-container.theme-default {
    --color_primary: #6f6f6f;
    --color_secondary: #5e5e5e;
    --color_tertiary: #343434;
    --color_quaternary: #d1d1d1;
    --color_quinary: #787878;
    --color-error: #ff2222;
    --color_header: #4a4a4a;
    --color_body: #676767;
    --color_highlightColor: #fdad51;
    --color_highlightColor2: #fef48b;
    --color-table-hover: #9d9d9d;
    --color-button-text: #2b2b2b;
    --color-button-bg: #5d5d5d;
    --color-button-bg-hover: #f59c34;
    --color-button-bg-active: #df8576;
    --color-table-border: #bbbbbb;
    --color-table-body: #cdcdcd;
    --color-table-text: #444444;
    --color-table-hover: #9d9d9d;
    --color-table-row-header: #f3f3f388;
    --color-table-row-even: #88888888;
    --color-table-row-odd: #bbbbbb88;
}
html[data-theme='dark'], .page-container.theme-dark {
    --color_primary: #3e3e3e;
    --color_secondary: #282828;
    --color_tertiary: #343434;
    --color_quaternary: #363636;
    --color_quinary: #252525;
    --color-error: #ff2222;
    --color_header: #28282b;
    --color_body: #181818;
    --color_highlightColor: #fdad51;
    --color_highlightColor2: #fef48b;
    --color-table-hover: #524;
    --color-button-text: #e4e4e4;
    --color-button-bg: #5d5d5d;
    --color-button-bg-hover: #f59c34;
    --color-button-bg-active: #df8576;
    --color-table-border: #323232;
    --color-table-body: #494949;
    --color-table-text: #c0c0c0;
    --color-table-hover: #4b4b4b;
    --color-table-row-header: #f3f3f388;
    --color-table-row-even: #4e4e4e88;
    --color-table-row-odd: #6b6b6b88;
}
html[data-theme='cool'], .page-container.theme-cool {
    --color_primary: #16295c;
    --color_secondary: #001058;
    --color_tertiary: #001862;
    --color_quaternary: #453979;
    --color_quinary: #242b44;
    --color-error: #ff2222;
    --color_header: #16295c;
    --color_body: #223874;
    --color_highlightColor: #2084bf;
    --color_highlightColor: #51b9f5;
    --color_highlightColor2: #baaeae;
    --color_highlightColor2: #debebe;
    --color-button-bg: #001058;
    --color-button-bg-hover: #2084bf;
    --color-button-bg-active: #f59c34;
    --color-table-border: #bbbbbb;
    --color-table-body: #cdcdcd;
    --color-table-text: #444444;
    --color-table-hover: #2084bf88;
    --color-table-row-header: #f3f3f388;
    --color-table-row-even: #52b7f144;
    --color-table-row-odd: #dddddd88;
}